import React,{Component} from 'react';
import Parse from '../services/Parse';

class ContactArea extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      name: '',
      username: '',
      email: '',
      phone: '',
      state: '',
      finished: false,
      usernameExists: false,
      loading: false,
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    this.setState({ loading: true, usernameExists: false });
    const query = new Parse.Query('TicketBusinessPartner');
    query.equalTo('handle', this.state.username.toLowerCase());
    const partner = await query.first();
    if (partner) {
      this.setState({ loading: false, usernameExists: true });
      return;
    }
    const newPartner = new Parse.Object('TicketBusinessPartner');
    const { name, username, phone, email, state } = this.state;
    await newPartner.save({
      name,
      email,
      state,
      phone: phone.replace(/\D/g, ''),
      handle: username.toLowerCase(),
      launchedLeads: [],
      ownedLeads: [],
    })
    this.setState({ loading: false, finished: true });
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    console.log(name, value)
    this.setState({ [name]: value });
  }

  render() {
    const extraClass = this.props.extraClass ? this.props.extraClass : ''
    let body = (
      <div>
        <span className="subtitle">Contact us</span>
        <h3 className="title">Become a Partner</h3>
        <form onSubmit={this.handleSubmit} id="contact_form_submit" className="contact-form sec-margin">
            <div className="row">
                <div className="col-lg-12">
                    <div className="form-group">
                        <input type="text" className="form-control" name="name" placeholder="Full Name" required value={this.state.name} onChange={this.handleInputChange}/>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <input type="text" className="form-control" name="username" placeholder="Username" required value={this.state.username} onChange={this.handleInputChange}/>
                        {this.state.usernameExists && <p style={{ color: 'red' }}>Username exists.</p>}
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <input type="text" className="form-control" name="email" placeholder="Email" required value={this.state.email} onChange={this.handleInputChange}/>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <input type="text" className="form-control" name="phone" placeholder="Phone" required value={this.state.phone} onChange={this.handleInputChange}/>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <input type="text" className="form-control" name="state" placeholder="State" required value={this.state.state} onChange={this.handleInputChange}/>
                    </div>
                </div>
                <div className="col-lg-12">
                    <button disabled={this.state.loading} className="submit-btn-02" type="submit">Submit Now</button>
                </div>
            </div>
        </form>
      </div>
    );
    if (this.state.finished) {
      body = (
        <div>
          <h3 className="title">Thank you for joining our Partner Program.</h3>
          <p>You will contact you shortly.</p>
        </div>
      );
    }
    return(
           <div className={`contact-form-area-02 contact-bg padding-bottom-120 padding-top-120 ${extraClass}`} id="contact">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                  <div className="contact-area-wrapper" id="contact">
                      {body}
                  </div>
                </div>
                <div className="col-lg-6">
                    <div className="img-wrapper">
                        <img style={{ paddingTop: 160 }} src={require('../assets/img/contact-us.png')} alt="contact mobile"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}
export default ContactArea;
