import React,{Component} from 'react';

function RenderParagraph(props){
  var paragraph = props.paragraph ? <p>{props.paragraph }</p> : '';
  return paragraph;
}
function RenderParagraph2(props){
  var paragraph = props.paragraph ? <div><br /><p>{props.paragraph }</p></div> : '';
  return paragraph;
}
function RenderButton(props){
  var buttonMarkup = props.buttonLink && props.buttonText ?  <div className="btn-wrapper"><a href={props.buttonLink} className="boxed-btn-02">{props.buttonText}</a></div> : '';
  return buttonMarkup;
}
class SectionTitle extends Component{
  render(){
    return(
      <div className={`section-title ${this.props.extraClass}`}>
          <span className="subtitle">{this.props.subtitle}</span>
          <h2 className="title">{this.props.title}</h2>
          <RenderParagraph paragraph={this.props.paragraph}/>
          <RenderParagraph2 paragraph={this.props.paragraph2}/>
          <RenderButton buttonLink={this.props.buttonLink}  buttonText={this.props.buttonText}/>
      </div>
    )
  }
}
export default SectionTitle;
