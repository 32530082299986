import React from 'react';
import ReactDOM from 'react-dom';
// import HomePageOne from './demos/HomeOne';
// import HomePageTwo from './demos/HomeTwo';
// import HomePageFour from './demos/HomeFour';
// import BlogPage from './demos/Blog';
// import BlogDetailsPage from './demos/BlogDetails';
import HomePageThree from './demos/HomeThree';
import HandlePage from './demos/Handle';

import './appside';
import {BrowserRouter,Route,Switch,Redirect} from 'react-router-dom';

ReactDOM.render(
  <BrowserRouter basename="/">
    <Switch>
      <Route path='/' component={() => { 
        window.location.href = 'https://cashdrop.biz/link/EZsVh8hQtE'; 
        return null;
      }}/>
      {/* <Route path="/" exact render={ props => <HomePageThree {...props} />} /> */}
      {/* <Route path="/home-1" exact render={ props => <HomePageOne {...props} />} />
      <Route path="/home-2" exact render={ props => <HomePageTwo {...props} />} />
      <Route path="/home-3" exact render={ props => <HomePageThree {...props} />} />
      <Route path="/home-4" exact render={ props => <HomePageFour {...props} />} />
      <Route path="/blog" exact render={ props => <BlogPage {...props} />} />
      <Route path="/blog-details" exact render={ props => <BlogDetailsPage {...props} />} /> */}
      {/* <Route path="/:handle" render={ props => <HandlePage {...props} />} /> */}
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
  , document.getElementById('root'));
