import React,{Component} from 'react';
import SectionTitle from './SectionTitle';

class CustomerReplyArea extends Component{
  state = {
    featureList : [
      {
        theme: 'theme-01',
        icon : 'fa fa-code',
        title: 'Clean Code',
        paragraph :'Messenger communication consectetur adipiscing elit, sed do eiusmod tempor labore.'
      },
      {
        theme: 'theme-02',
        icon : 'fa fa-comments',
        title: 'Fully Live Chat',
        paragraph :'Messenger communication consectetur adipiscing elit, sed do eiusmod tempor labore.'
      },
      {
        theme: 'theme-03',
        icon : 'fa fa-desktop',
        title: 'Easy Dashboard',
        paragraph :'Messenger communication consectetur adipiscing elit, sed do eiusmod tempor labore.'
      },
      {
        theme: 'theme-04',
        icon : 'fa fa-columns',
        title: 'Customizable Design',
        paragraph :'Messenger communication consectetur adipiscing elit, sed do eiusmod tempor labore.'
      },
    ]
  }
  render(){
    return(
      <section className="customer-reply-area padding-top-120">
          <div className="right-image">
              <img src={require('../assets/img/mobile-side-image.png')} alt="right side mobile"/>
          </div>
          <div className="container">
              <div className="row">
                  <div className="col-lg-6">
                      <div className="section-title-wrap">
                      <SectionTitle
                        extraClass='left-aligned'
                        subtitle='Our Mission'
                        title= 'Revolutionize the digital marketplace'
                        paragraph='We’re on a mission to help local businesses escape the crazy high fees of e-commerce platforms - our app is %100 free to use for businesses.'
                        paragraph2='By eliminating predatory fees, maximizing online orders and providing a seamless buying experience we help create sustainability for businesses using CashDrop big and small.'
                        buttonLink='#contact'
                        buttonText='Become a Partner'
                      />
                      </div>
                  </div>
              </div>
          </div>
          <div className="amazing-feature-area padding-top-55 padding-bottom-80">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-lg-10">
                      <SectionTitle
                      subtitle='A Trusted Stream of Income'
                      title='Verified Partner'
                      paragraph='Become a Verified Partner after launching 10 accounts and start earning $250 per launched account.
                      Receive 15% commissions on each account for 12 months after becoming a verified partner.'
                      paragraph2=''
                      />
                      </div>
                  </div>
                  <div className="row">
                  {/* {
                    this.state.featureList.map((feature,index) => (
                      <div className="col-lg-6" key={index}>
                          <div className={`single-amazing-feature-01 margin-bottom-30 ${feature.theme}`}>
                              <div className="icon">
                                  <i className={feature.icon}></i>
                              </div>
                              <div className="content">
                                  <h4 className="title">{feature.title}</h4>
                                  <p>{feature.paragraph}</p>
                              </div>
                          </div>
                      </div>
                    ))
                  } */}
                  </div>
              </div>
          </div>
      </section>
    )
  }
}
export default CustomerReplyArea;
