import React,{Component} from 'react';
import $ from 'jquery';

class BackToTopOne extends Component {
  componentDidMount(){

    $(document).ready(function(){
      /*-------------------------------
          back to top
      ------------------------------*/
      $(document).on('click', '.back-to-top', function () {
          $("html,body").animate({
              scrollTop: 0
          }, 2000);
      });
    });

    $(window).on('scroll', function () {
        /*---------------------------
            back to top show / hide
        ---------------------------*/
       var ScrollTop = $('.back-to-top');
       if ($(window).scrollTop() > 1000) {
           ScrollTop.fadeIn(1000);
       } else {
           ScrollTop.fadeOut(1000);
       }
    });

    $(window).on('load',function(){
        /*-----------------------------
            back to top
        -----------------------------*/
        var backtoTop = $('.back-to-top')
        backtoTop.fadeOut(100);
    });

  }
  render(){
    return(
      <div className="back-to-top">
            <i className="fas fa-angle-up"></i>
      </div>
    )
  }
}
export default BackToTopOne;
