import React,{Component} from 'react';
import { Redirect } from "react-router-dom";

import Navbar from './components/Navbar';
import HeaderAreaHandle from './components/HeaderAreaHandle';
import Parse from './services/Parse';

class HandlePage extends Component{
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      finished: false,
      error404: false,
    };
    this.onFinish = this.onFinish.bind(this);
  }

  async componentDidMount() {
    const { handle } = this.props.match.params;
    const query = new Parse.Query('TicketBusinessPartner');
    query.equalTo('handle', handle);
    const user = await query.first();
    if (!user) {
      this.setState({ error404: true })
    } else {
      this.setState({ user });
    }
  }

  async onFinish(data) {
    const lead = new Parse.Object('TicketBusinessLead');
    await lead.save({
      partner: this.state.user,
      handle: data.selectedUser.user.username,
      name: data.selectedUser.user.full_name,
      phone: data.phone.replace(/\D/g, ''),
      state: data.state,
      email: data.email,
      notes: data.notes,
      status: 'pending',
    });
    const createLog = new Parse.Object('TicketBusinessLeadLog');
    const assignLog = new Parse.Object('TicketBusinessLeadLog');
    createLog.set('type', 'create');
    createLog.set('lead', lead);
    assignLog.set('type', 'assign');
    assignLog.set('lead', lead);
    assignLog.set('partner', this.state.user);

    await Parse.Object.saveAll([createLog, assignLog]);
    this.state.user.addUnique('ownedLeads', lead.id);
    await this.state.user.save();

    const text = `
Partner: ${this.state.user.get('name')}\n
Name: ${lead.get('name')}\n
Handle: https://instagram.com/${lead.get('handle')}\n
State: ${lead.get('state')}\n
Phone: ${lead.get('phone')}\n
Email: ${lead.get('email')}\n
Notes: ${lead.get('notes')}\n
`
    await Parse.Cloud.run('sendEmail', {
      to: 'info@cashdrop.biz',
      from: 'diamond@cashdrop.biz',
      subject: 'New Lead',
      text
    });
    this.setState({ finished: true });
  }

  render() {
    if (this.state.error404) {
      return <Redirect to={"/"} />
    }
    let body = (<HeaderAreaHandle finish={this.onFinish}/>);
    if (this.state.finished) {
      body = (
        <header className="header-area header-bg-12 style-12" id="home">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-lg-8">
                      <div className="header-inner">
                          <h1 className="title wow fadeInDown" >Thank you for submitting a lead. You will receive your bounty once this account goes live!</h1>
                      </div>
                  </div>
              </div>
          </div>
      </header>
      )
    }
    return(
      <main rel="main">
        <Navbar extraClass="header-style-12" siteLogo={require('./assets/img/cashLogoBlack.png')}/>
        {body}
      </main>
    )
  }
}
export default HandlePage;
