import React,{Component} from 'react';
import Navbar from './components/Navbar';
import HeaderThree from './components/HeaderThree';
import ContactArea from './components/Contact';
import BackToTopOne from './components/BackToTop';
import ConnectYou from './components/ConnectYou';
import CustomerReplyArea from './components/CustomerReplyArea';

// import PricingAreaOne from './components/PricingAreaOne';
// import AmazingInterface from './components/AmazingInterface';
// import TeamMemberArea from './components/TeamMemberArea';
// import CallToActionOne from './components/CallToActionOne';
// import FooterOne from './components/Footer';
// import VideoArea from './components/VideoArea';
// import BrandLogos from './components/BrandLogos';
// import TestimonialAreaTwo from './components/TestimonialTwo';

class HomePageThree extends Component{
  render(){
    return(
      <main rel="main">
        <Navbar extraClass="header-style-09 white" siteLogo={require('./assets/img/cashLogoWhite.png')}/>
        <HeaderThree/>
        {/* <BrandLogos/> */}
        <ConnectYou/>
        <CustomerReplyArea/>
        {/* <VideoArea/>
        <TestimonialAreaTwo extraClass='mirgin-minus'/> */}
        {/* <AmazingInterface/> */}
        {/* <PricingAreaOne extraClass='gray-bg'/> */}
        {/* <TeamMemberArea/> */}
        <ContactArea extraClass='gray-bg'/>
        {/* <CallToActionOne extraClass='gray-bg'/>
        <FooterOne/> */}
        <BackToTopOne/>
      </main>
    )
  }
}
export default HomePageThree;
