import React,{Component} from 'react';

class ConnectArea extends Component{
  render(){
    return(
      <section className="connect-area padding-top-120" id="about">
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-6">
                    <div className="row">
                        <div className="col-lg-12">
                           <div className="right-connect-you-wrap">
                                <img style={{ borderRadius: 10 }} src={require('../assets/img/cashrop-all.jpg')} alt="open" />
                           </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="right-content-area">
                        <h2 className="title">How It Works</h2>
                        <ol style={{ lineHeight: '40px' }}>
                          <li>Join the program and receive a custom link</li>
                          <li>Refer businesses of all kinds to use CashDrop</li>
                          <li>They sign up and make a sale</li>
                          <li>Tada! You receive $100 up front</li>
                          <li>Build a residual income!</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
  }
}

export default ConnectArea;
