import React,{Component} from 'react';

class HeaderThree extends Component{
  render(){
    return(
      <header className="header-area header-bg-11 style-11" id="home">
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-6">
                    <div className="header-inner">
                        <h1 className="title wow fadeInDown" >Let's Grow Together with our Partner Program</h1>
                        <p>Earn $100 for every business that downloads CashDrop using your link, it's that simple.</p>
                        <div className="button-wrapper wow fadeInUp">
                            <a href="#contact" className="boxed-btn-02"> Start Making Money with Us</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="right-img">
                        <div className="img-wrapper">
                            <img src={require('../assets/img/header-right-image.png')} alt="header right"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    )
  }
}
export default HeaderThree;
