const Parse = require('parse');
const appId = 'MK5KVBqIzhhM5tIwX9hrKnQLLKpHeJ9O0VHS4Fqp';
const jsKey = 'CLStTSaozrHYjthLXzZkqoUCsJcgnFVQtb5rjtyu';
const serverURL = 'https://sugr-live-query.herokuapp.com/parse';
// this.serverURL = 'http://rubens-macbook-pro.local:1337/parse';
// this.serverURL = 'http://localhost:1337/parse';
Parse.initialize(appId, jsKey);
Parse.serverURL = serverURL;

export default Parse;
