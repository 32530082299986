import React,{ Component } from 'react';

class HeaderAreaHandle extends Component{
  constructor(props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.onClick = this.onClick.bind(this);
      this.state = {
        value: '',
        users: [],
        selectedUser: null,
        loading: false,
        state: '',
        phone: '',
        email: '',
        notes: '',
      };
  }

  handleChange(event) {
    const { value } = event.target;
    this.setState({ value });
    if (value.length < 3) {
      return;
    }
    fetch(`https://www.instagram.com/web/search/topsearch/?context=blended&query=${value}&rank_token=0.5764164512568422&include_reel=true`)
      .then(res => res.json())
      .then(result => this.setState({ users: result.users || [] }));
  }

  handleSubmit(event) {
    this.setState({ loading: true });
    this.props.finish(this.state);
    event.preventDefault();
  }

  onClick(selectedUser) {
    this.setState({ selectedUser });
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    let autocomplete = null;
    if (this.state.users.length) {
      autocomplete = (
        <div>
          {this.state.users.map((user, index) => {
            const handleClick = () => {
              this.onClick(user);
            };
            return (
              <div className="autocomplete-section" key={index} onClick={handleClick}>
                <img className="autocomplete-image" src={user.user.profile_pic_url} alt="Logo"/>
                <div className="autocomplete-main">{user.user.full_name}</div>
                <div className="autocomplete-secondary">{user.user.username}</div>
              </div>
            )
          })}
        </div>
      )
    }
    let form = (
      <div className="form-group">
        <input type="text" style={{ height: 80 }} className="form-control" placeholder="Search Instagram" value={this.state.value} onChange={this.handleChange} />
        {autocomplete}
      </div>
    );
    if (this.state.selectedUser) {
      form = (
        <form onSubmit={this.handleSubmit} className="search-form">
          <div className="form-group">
            <input type="text" className="form-control" name="state" placeholder="State" required value={this.state.state} onChange={this.handleInputChange}/>
          </div>
          <div className="form-group">
            <input type="text" className="form-control" name="phone" placeholder="Phone Number" value={this.state.phone} onChange={this.handleInputChange}/>
          </div>
          <div className="form-group">
            <input type="text" className="form-control" name="email" placeholder="Email" value={this.state.email} onChange={this.handleInputChange}/>
          </div>
          <div className="form-group">
            <textarea className="form-control" name="notes" placeholder="Notes" value={this.state.notes} onChange={this.handleInputChange} />
          </div>
          <div className="form-group">
            <button disabled={this.state.loading} className="submit-btn-02" type="submit">Submit</button>
          </div>
        </form>
      )
    }
    return(
      <header className="header-area header-bg-12 style-12" style={{ paddingTop: 140 }} id="home">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-lg-8">
                      <div className="header-inner">
                          <h1 className="title wow fadeInDown" >Earn $100 for every account you help us launch!</h1>
                          <div className="btn-wrapper wow fadeInUp">
                            {form}
                          </div>
                          <div className="bottom-image wow fadeInUp margin-top-80" >
                              <img src={require('../assets/img/bottom-image-cashdrop.png')} alt="logo" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </header>
    )
  }
}
export default HeaderAreaHandle;
