import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';

class Navbar extends Component{
  componentDidMount(){
    $(document).on('click','#appside_main_menu li a', function (e) {
        var anchor = $(this).attr('href');
        var link = anchor.slice(0,1);
        if ('#' === link) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $(anchor).offset().top
            }, 1000);
            $(this).parent().addClass('active').siblings().removeClass('active');
        }

    });
  }
  render(){
    return(
      <nav className={`navbar navbar-area navbar-expand-lg nav-absolute nav-style-01 ${this.props.extraClass}`}>
          <div className="container nav-container">
              <div className="responsive-mobile-menu">
                  <div className="logo-wrapper">
                    <Link to="/" className="logo">
                      <img src={this.props.siteLogo} alt="logo"/>
                    </Link>
                  </div>
                  {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#appside_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                  </button> */}
              </div>
              {/* <div className="collapse navbar-collapse" id="appside_main_menu">
                  <ul className="navbar-nav">
                      <li className="current-menu-item">
                          <a href="#!">Home</a>
                      </li>
                      <li><a href="#about">About</a></li>
                      <li><a href="#pricing">Pricing</a></li>
                      <li><a href="#team">Team</a></li>
                      <li className="menu-item-has-children">
                          <a href="#!">Blog</a>
                          <ul className="sub-menu">
                              <li>
                              <Link to="/blog">Blog 01</Link>
                              </li>
                              <li>
                              <Link to="/blog-details">Blog Details</Link>
                              </li>
                          </ul>
                      </li>
                      <li><a href="#contact">Contact</a></li>
                  </ul>
              </div>
              <div className="nav-right-content">
                  <ul>
                      <li className="button-wrapper">
                          <a href="#!" className="boxed-btn">Download</a>
                      </li>
                  </ul>
              </div> */}
          </div>
      </nav>
    )
  }
}

export default Navbar;
